import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as Ant from "antd";
import { navigate } from "gatsby";

import Widget, { Font, Color } from "../Widget";
import * as Icon from "../Icon";
import * as Survey from "../../Contexts/SurveyContext";
import { ExclamationCircleFilled } from "@ant-design/icons";

let windowGlobal = typeof window !== "undefined" && window;

const TempelateCard = props => {
  let {
    item,
    width,
    setSelectedTemplate,
    setShowEditTitleModal,
    setShowEditCategoryModal,
    setShowImportTemplateModal,
    popup,
    type,
    profile,
    fetchAllTemplate,
    isHidden,
    fromSurvey,
  } = props;
  const [popoverVisible, setPopoverVisible] = useState(false);
  const preview = useRef();
  const monitor = useRef();

  // 監控邏輯預覽視窗是否關閉
  const monitorPreview = () => {
    const { current: child } = preview;
    if (child?.closed) {
      window.clearInterval(monitor.current);
    }
  };

  const deleteTemplates = item => {
    try {
      let resp = Survey.Actions.deleteTemplate(item.TemplateId).then(() => {
        // props.setUpdater(props.updater + 1);
        fetchAllTemplate();
      });
    } catch (err) {}
  };

  async function _deleteTemplates(item) {
    Ant.Modal.confirm({
      title: "確認要刪除此範本嗎？",
      content: "一但刪除，即無法找回。",
      okText: "取消",
      okType: "primary",
      onOk: () => 0,
      cancelButtonProps: { type: "ghost" },
      cancelText: "確認刪除",
      onCancel: async () => {
        await deleteTemplates(item);
      },
      icon: <ExclamationCircleFilled color={Color.LightGold} size={20} />,
    });
  }

  const handleOpenPreview = async () => {
    setSelectedTemplate(item);
    setPopoverVisible(false);
    console.log("fromSurvey = ", fromSurvey);
    var previewUrl = `/templatepreview/?id=${item.SurveyId}&template=${item.TemplateId}`;
    if (fromSurvey !== undefined && fromSurvey !== null) {
      previewUrl += `&from=${fromSurvey.id}`;
    }
    preview.current = window.open(previewUrl, "_blank");
  };

  // 用以監控另開視窗是否關閉
  useEffect(() => {
    const { current: child } = preview;
    if (child) {
      if (child.closed) {
        // 視窗已關閉
        window.clearInterval(monitor.current);
      } else {
        // 視窗還未關閉，繼續監聽
        monitor.current = setInterval(monitorPreview, 500);
      }
    }
  }, [preview.current]);

  return (
    <TemplateCardWrapper
      width={width}
      popup={popup}
      isHidden={isHidden}
      onDoubleClick={handleOpenPreview}
    >
      <div className="main">
        <Font.Head className="title">{item.Subject}</Font.Head>
      </div>
      <div className="bottom">
        <Widget.FlexRow>
          <Font.Body style={{ color: Color.GreyBlack_25 }}>
            {`${item.TotalQuestionNum} 題`}
          </Font.Body>
          <div className="tag" onClick={handleOpenPreview}>
            預覽
          </div>
        </Widget.FlexRow>
        <Ant.Popover
          placement="bottomLeft"
          visible={popoverVisible}
          onVisibleChange={visible => setPopoverVisible(visible)}
          content={
            <div
              style={{
                color: Color.GreyBlack,
                minWidth: 122,
                marginLeft: -16,
                marginRight: -16,
              }}
            >
              <Font.Body
                className="hover-mask"
                style={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                onClick={() => {
                  setSelectedTemplate(item);
                  setShowImportTemplateModal(true);
                  setPopoverVisible(false);
                }}
              >
                匯入問卷
              </Font.Body>
              <Font.Body
                className="hover-mask"
                style={{
                  paddingTop: 4,
                  paddingBottom: 4,
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
                onClick={handleOpenPreview}
              >
                預覽
              </Font.Body>

              {type === "customization" || profile?.RoleId === 1 ? (
                <Font.Body
                  className="hover-mask"
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                  onClick={() => {
                    setSelectedTemplate(item);
                    setPopoverVisible(false);
                    setShowEditTitleModal(true);
                  }}
                >
                  重新命名
                </Font.Body>
              ) : null}

              {type === "public" && profile?.RoleId === 1 && (
                <Font.Body
                  className="hover-mask"
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                  onClick={() => {
                    setSelectedTemplate(item);
                    setPopoverVisible(false);
                    setShowEditCategoryModal(true);
                  }}
                >
                  移動問卷類別
                </Font.Body>
              )}

              {type === "customization" || profile?.RoleId === 1 ? (
                <Font.Body
                  className="hover-mask"
                  style={{
                    paddingTop: 4,
                    paddingBottom: 4,
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                  onClick={() => {
                    _deleteTemplates(item);
                    setPopoverVisible(false);
                  }}
                >
                  刪除
                </Font.Body>
              ) : null}
            </div>
          }
          trigger="click"
        >
          <Icon.Ellipsis size={26} color={Color.Purple} />
        </Ant.Popover>
      </div>
    </TemplateCardWrapper>
  );
};

const TemplateCardWrapper = styled.div`
  padding: 18px 20px;
  border-radius: 5px;
  display: flex;
  display: ${props => (props.isHidden ? "none" : "flex")};
  flex-direction: column;
  width: ${props => props.width || "200px"};
  height: 149px;
  margin-right: 24px;
  margin-bottom: 16px;
  background-color: ${Color.GreyWhite};
  border: 1px solid ${Color.PurpleLight_2};
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);

  ${props =>
    props.popup
      ? ` 
        width : calc((100% - 24 * 2px) / 3);

        &:nth-child(3n) {
          margin-right: 0px;
        }
      `
      : `
    /*如果螢幕寬度為 1680px 以下，就套用 css 設定*/
    @media screen and (max-width: 1680px){ 
      width : calc((100% - 24 * 3px) / 4);

      &:nth-child(4n) {
        margin-right: 0px;
      }
    }

    /*如果螢幕寬度為 1680px 以上，就套用 css 設定*/
    @media screen and (min-width: 1680px){ 
      width : calc((100% - 24 * 4px) / 5);

      &:nth-child(5n) {
        margin-right: 0px;
      }
    }
  `}

  & > .main {
    flex: 1;
  }

  & > .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;

    .tag {
      display: none;
      width: 52px;
      height: 22px;
      line-height: 22px;
      border-radius: 11px;
      margin-left: 12px;
      background-color: ${Color.LightPurple_45};
      font-size: 14px;
      color: #edecf7;
      text-align: center;
      cursor: default;
    }
  }

  &:hover {
    background-color: #f6f5ff;
    border-color: #756ec2;

    .tag {
      display: block;
    }
  }
`;

export default TempelateCard;
